// #ifdef H5
// @ts-expect-error
import Aegis from 'aegis-web-sdk'

// #endif
// #ifndef H5
// @ts-expect-error
import Aegis from 'aegis-mp-sdk'

// #endif

const createAegis = (() => {
  let instance: any = null

  if (import.meta.env.VITE_ENV !== 'production') {
    return () => {
      return {
        infoAll: () => {

        },
      }
    }
  }

  return () => {
    // #ifdef H5
    // @ts-expect-error
    const id = 'Gl8ngfEDE5qKkyL4e7'
    // #endif
    // #ifndef H5
    // @ts-expect-error
    const id = '75eydIQ3aoYRvlW6Od'
    // #endif
    if (!instance) {
      instance = new Aegis({
        id, // 上报 id
        reportApiSpeed: true, // 接口测速
        spa: false, // spa 应用页面跳转的时候开启 pv 计算
        reportAssetSpeed: true, // 静态资源测速
        hostUrl: 'https://rumt-zh.com',
        api: {
          apiDetail: true,
          retCodeHandler: (data: any, url: string, xhr: any) => {
            if (url.startsWith('https://api.liuliangguo.com')) {
              return {
                isErr: false,
                code: 0,
              }
            }
            if (url.startsWith('https://api.ipify.org')) {
              if (JSON.parse(data || '{}').ip) {
                return {
                  isErr: false,
                  code: 0,
                }
              }
            }
            if (
              url.startsWith(
                'https://tc-admin.xmonecode.com/open/api/activity/placeCardOrder',
              )
            ) {
              const innerCode = JSON.parse(data || '{}').innerCode
              if (innerCode && innerCode !== 10500) {
                return {
                  isErr: false,
                  code: 0,
                }
              }
            }
            if (url.startsWith('https://mcs.snssdk.com')) {
              return {
                isErr: false,
                code: 0,
              }
            }
            if (
              url.startsWith(
                'https://data-report.xmonecode.com/data-report/common/report',
              )
            ) {
              const message = JSON.parse(data || '{}').message
              if (message === '成功') {
                return {
                  isErr: false,
                  code: 0,
                }
              }
            }
            // 屏蔽字节图片OCR识别接口
            if (
              url.startsWith(
                'https://bess.bytedance.net/audit/extension/ocr/image-black-word',
              )
            ) {
              return {
                isErr: false,
                code: 0,
              }
            }
            // 屏蔽gdps SDK请求
            if (
              url.startsWith(
                'https://collect.gmcc.net',
              )
            ) {
              return {
                isErr: false,
                code: 0,
              }
            }
            try {
              data = JSON.parse(data)
            }
            catch (e) {}

            return {
              isErr: data.code !== 200,
              code: data.innerCode,
            }
          },
        },
        beforeRequest(data: any) {
          // 用户取消请求不上报
          if (
            data.logType === 'log'
            && data.logs.level === '8'
            && data.logs.msg.includes('"code": "-1"')
          )
            return false

          // 用户取消请求不上报
          if (
            data.logType === 'log'
            && data.logs.level === '16'
            && (data.logs.msg.includes('fail:network interrupted error')
              || data.logs.msg.includes('fail:time out')
              || data.logs.msg.includes('ERR_CONNECTION_ABORTED')
              || data.logs.msg.includes('request:fail abort'))
          )
            return false

          // JS错误
          if (
            data.logType === 'log'
            && data.logs.level === '4'
          ) {
            if (data.logs.msg.includes('Uncaught TypeError: Cannot destructure property \'id\' of \'e\' as it is null.'))
              return false

            // 跨域
            else if (data.logs.msg.includes('Script error.'))
              return false
          }
        },
      })
    }
    return instance
  }
})()

export default createAegis
