import { createSSRApp } from 'vue'
import * as Pinia from 'pinia'
import App from './App.vue'
import 'uno.css'

// #ifdef H5
import LazyImage from './components/lazy-image/index.vue'
import './lib/iconfont'

// #endif
import Skeleton from './components/skeleton/index.vue'
import Icon from './components/icon/index.vue'
import '../istanbul-coverage/nyc-client'

export function createApp() {
  const app = createSSRApp(App)
  // #ifdef H5
  app.component('lazy-image', LazyImage)
  // #endif
  app.component('skeleton', Skeleton)
  app.component('tx-icon', Icon)
  app.use(Pinia.createPinia())
  return {
    app,
    Pinia,
  }
}
