<script setup lang="ts">
import { onHide, onLaunch, onShow } from '@dcloudio/uni-app'
import createAegis from './utils/create-aegis'

// import "./utils/kf-crbt-sdk.js";

let options = {}
onLaunch((_options) => {
  options = _options || {}
  createAegis()
  console.log('App Launch')
})
onShow(() => {
  console.log('App Show')
})
onHide(() => {
  console.log('App Hide')








  console.log(window.navigator.userAgent)

})
</script>

<style>
@import './static/css/index.css';
</style>
